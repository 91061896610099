import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

export function getRoutes(query) {
    let queryString = qs.stringify(query);

    return axios.get('/mobility-routes?' + queryString);
}
export function importCsv(data, options) {
    let formData = new FormData();
    formData.append('files.files', data);
    formData.append('data', JSON.stringify({ strapi: true }));
    return axios.post('/mobility-routes/import-csv', formData, options);
}
